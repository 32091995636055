<template>
  <t-dropdown severity="secondary"
              :disabled="disabled"
              class="position-relative"
              :class="[dropdownClasses, { 'cursor-disabled': disabled, 'mw-100': fillWidth }]"
              :toggle-class="[
                btnClasses,
                'white-space-normal text-left',
                { 'overflow-hidden d-flex-center': overflowHidden },
                { 'p-0 text-decoration-none border-bottom my-2 border-left-0 border-right-0 border-top-0 border-radius-0 bg-transparent' : !button}
              ]"
              menu-class="bulk-filter-options-menu"
              @shown="$emit('focus')"
              @hidden="$emit('blur')">
    <template #button-content>
      <fluency-icon v-if="disabled" type="lock" />
      <slot name="button-content">
        <span v-if="formatter(value) || value === false"
              :class="{ 'text-black-50': subtle, 'overflow-hidden text-overflow-ellipsis': overflowHidden }">{{ formatter(optionObjectParser(value)) }}</span>
        <span v-else :class="['font-italic', subtle ? 'text-more-muted' : 'text-muted', { 'text-danger': error }]">{{ placeholder }}</span>
        </slot>
    </template>
    <t-dropdown-item v-for="(option, index) in options"
                     :key="`${formatter(option)}${index}`"
                     :active="pluckObjValue(option) === value"
                     :link-class="{ 'white-space-normal': overflowHidden }"
                     variant="text"
                     :disabled="option.hasOwnProperty('disabled') && option.disabled === true"
                     @click="input(option)">
      <slot name="dropdown-item-content" :option="option" :text="formatter(pluckObjText(option))">
        <span :class="{ 'font-weight-bold': option.description }">{{ formatter(pluckObjText(option)) }}</span>
        <i v-if="option.description">&nbsp;&mdash;&nbsp;{{option.description}}</i>
      </slot>
    </t-dropdown-item>
    <slot name="additional-content"></slot>
  </t-dropdown>
</template>

<script>
export default {
  name: 'MadLibSelect',
  props: {
    value: {
      type: [String, Number, Object, Boolean]
    },
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: 'Please Select'
    },
    formatter: {
      type: Function,
      default: (v) => v
    },
    fillWidth: {
      type: Boolean,
      default: false
    },
    btnClasses: {
      type: String,
      default: ''
    },
    dropdownClasses: {
      type: String,
      default: ''
    },
    subtle: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    overflowHidden: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    boundary: {
      type: String,
      default: 'window'
    },
    button: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'link'
    }
  },
  methods: {
    pluckObjValue (inp) {
      return (inp.hasOwnProperty('value')) ? inp.value : inp
    },
    pluckObjText (inp) {
      if (inp.hasOwnProperty('text')) {
        return inp.text
      }

      if (inp.hasOwnProperty('value')) {
        return inp.value
      }

      return inp
    },
    optionObjectParser (val) {
      const str = (this.options || []).find(o => {
        // yes, I meant to use ==
        if (o.hasOwnProperty('value') && o.hasOwnProperty('text') && val == o.value) {
          return true
        }
        if (o.hasOwnProperty('value') && val == o.value) {
          return true
        }

        return false
      })

      return (str) ? (str.text) ? str.text : str.value : val
    },
    input (option) {
      this.$emit('input', this.pluckObjValue(option))
      this.$emit('blur')
    }
  }
}
</script>

<style lang="scss">
.bulk-filter-options-menu {
  max-height: 40vh;
  overflow-x: clip;
  overflow-y: auto;
}
</style>
